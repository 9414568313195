<template>
	<div
		id="details"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<h1 class="mb-10">Avtala</h1>
		<p class="mb-30 color-grey">Rætta avtaluna við {{ value.customer && value.customer.name ? value.customer.name : '' }}</p>

		<div class="mb-20">
			<label
				class="block mb-10"
				for="currency"
				>Kundi</label
			>

			<div class="selected-customer disabled">
				<div
					class="customer-name"
					v-html="value.customer && value.customer.name"
				>
					&nbsp;
				</div>
			</div>
		</div>

		<div class="grid grid-2 grid-gap-20 mb-20">
			<div>
				<label
					class="block mb-10"
					for="currency"
					>Slag av avtalu</label
				>

				<select
					class="v-select"
					id="agreementType"
					v-model="form.selectedType.name"
				>
					<option
						v-for="(agreementType, index) in agreementTypes"
						:key="`agreement_type_${index}`"
						:value="agreementType.name"
					>
						{{ agreementType.name }}
					</option>
				</select>
			</div>

			<div>
				<label
					class="block mb-10"
					for="currency"
					>Leistur at fylgja</label
				>

				<select
					class="v-select"
					id="stepList"
					v-model="form.selectedStepList"
				>
					<option value="">Ongin leistur</option>
					<option
						v-for="(stepList, index) in stepLists"
						:key="`step_list_${index}`"
						:value="{ id: stepList.id }"
					>
						{{ stepList.name }}
					</option>
				</select>
			</div>
		</div>

		<template v-if="!hasField('to')">
			<div class="mb-30">
				<v-date-picker
					v-model="form.from"
					mode="dateTime"
					is24hr
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Avtala byrjar</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.to"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.to"
									@click="form.to = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>
			</div>
		</template>

		<template v-if="hasField('to')">
			<div class="grid grid-2 grid-gap-20 mb-30">
				<v-date-picker
					v-model="form.from"
					mode="dateTime"
					is24hr
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Avtala byrjar</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.from"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.from"
									@click="form.from = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>

				<v-date-picker
					v-model="form.to"
					mode="dateTime"
					is24hr
					:min-date="form.from ? form.from : null"
					:model-config="{ timeAdjust: '23:59' }"
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Avtala endar</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.to"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.to"
									@click="form.to = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>
			</div>
		</template>

		<label
			v-show="hasField('isShortTermAgreement')"
			class="cursor-pointer"
		>
			<div class="mb-10">Vikuskiftisleiga</div>

			<div
				class="switch"
				:class="{ active: form.isShortTermAgreement }"
			>
				<input
					type="checkbox"
					v-model="form.isShortTermAgreement"
				/>

				<div></div>
			</div>
		</label>

		<hr class="my-20" />

		<h2 class="mb-10">Kostnaðir</h2>

		<div class="grid grid-2 grid-gap-30 mb-30">
			<div v-show="hasField('one_time_price')">
				<label class="block mb-10">Eingangsgjald</label>

				<input
					type="text"
					v-model="form.oneTimePrice"
				/>
			</div>

			<div v-show="hasField('monthlyPrice')">
				<label class="block mb-10">Mánaðargjald</label>
				<input
					type="text"
					v-model="form.monthlyPrice"
				/>
			</div>

			<div v-show="hasField('fee_price')">
				<label class="block mb-10">Avgreiðslugjald</label>

				<input
					type="text"
					v-model="form.feePrice"
				/>
			</div>
		</div>

		<hr class="my-20" />

		<h2 class="mb-10">Avtalu upplýsingar</h2>

		<div class="grid grid-2 grid-gap-30 mb-30">
			<div v-show="hasField('swedbank_e_com_number')">
				<label class="block mb-10">Swedbank E-Com nummar</label>
				<input
					type="text"
					v-model="form.swedbank_e_com_number"
				/>
			</div>

			<div v-show="hasField('swedbank_pos_number')">
				<label class="block mb-10">Swedbank POS nummar</label>
				<input
					type="text"
					v-model="form.swedbank_pos_number"
				/>
			</div>

			<div v-show="hasField('tof_number')">
				<label class="block mb-10">TOF</label>
				<input
					type="text"
					v-model="form.tof_number"
				/>
			</div>

			<div v-show="hasField('numberOfTerminals')">
				<label class="block mb-10">Tal av terminalum</label>

				<input
					type="number"
					v-model="form.numberOfTerminals"
					min="1"
				/>
			</div>
		</div>

		<div
			class="mb-30"
			v-show="hasField('terms')"
		>
			<label class="block mb-10">Treytir</label>

			<textarea
				v-model="form.terms"
				cols="30"
				rows="4"
				style="resize: none"
			></textarea>
		</div>

		<div v-show="hasField('terminalSupplier') || hasField('terminalType') || hasField('psp') || hasField('otherPsp')">
			<hr class="my-20" />

			<h2 class="mb-10">Terminal upplýsingar</h2>

			<div class="grid grid-2 grid-gap-30">
				<div v-show="hasField('terminalSupplier')">
					<label class="block mb-10">Terminal Leverandør</label>
					<input
						type="text"
						v-model="form.terminalSupplier"
					/>
				</div>

				<div v-show="hasField('terminalType')">
					<label class="block mb-10">Slag av terminali</label>
					<input
						type="text"
						v-model="form.terminalType"
					/>
				</div>

				<label
					v-show="hasField('psp')"
					class="align-center cursor-pointer"
				>
					<div class="mb-10">PSP Nets?</div>

					<div
						class="switch"
						:class="{ active: form.psp }"
					>
						<input
							type="checkbox"
							v-model="form.psp"
						/>

						<div></div>
					</div>
				</label>

				<div v-show="hasField('otherPsp') && !form.psp">
					<label class="block mb-10">Annað PSP</label>
					<input
						type="text"
						v-model="form.otherPsp"
					/>
				</div>
			</div>
		</div>

		<hr class="my-20" />

		<div class="grid mt-20">
			<h2 class="mb-20">Skjøl at útfylla</h2>

			<div class="grid grid-2 grid-gap-10">
				<label
					style="font-weight: normal"
					class="cursor-pointer"
					v-for="(originalFile, index) in originalFiles"
					:key="`original_file_${index}`"
				>
					<input
						type="checkbox"
						:value="originalFile"
						v-model="form.originalFiles"
					/>

					<span class="ml-10">{{ originalFile.name }}</span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';

import axios from 'axios';

export default {
	name: 'EditAgreementDetails',

	props: ['value'],

	data() {
		return {
			originalFiles: [],

			agreementTypes: [],

			stepLists: [],

			loaded: false,

			form: {
				originalFiles: [],
				stepList: {},
				customer: null,
				swedbank_e_com_number: null,
				swedbank_pos_number: null,
				tof_number: null,
				from: null,
				to: null,
				feePrice: '',
				monthlyPrice: '',
				price: '',
				oneTimePrice: '',
				terms: '',
				terminalSupplier: '',
				terminalType: '',
				psp: true,
				otherPsp: '',
				isShortTermAgreement: false,
				numberOfTerminals: 1,

				// Needs to be nested in 'form' to be watched together with all other data
				selectedType: { name: null },
				selectedStepList: { id: null },
			},
		};
	},

	watch: {
		value: {
			handler(value) {
				if (this.loaded) {
					return;
				}

				this.loaded = true;

				this.$nextTick(() => {
					this.loaded = false;
				});

				this.load(value);
			},
		},

		form: {
			deep: true,

			handler() {
				this.$emit('input', {
					type: this.form.selectedType,
					customer: this.form.customer,
					stepList: this.form.selectedStepList ?? '',
					swedbank_e_com_number: this.form.swedbank_e_com_number,
					swedbank_pos_number: this.form.swedbank_pos_number,
					tof_number: this.form.tof_number,
					from: this.form.from ? new Date(this.form.from) : null,
					to: this.form.to ? new Date(this.form.to) : null,
					terms: this.form.terms,
					originalFiles: this.form.originalFiles,
					oneTimePrice: this.form.oneTimePrice,
					feePrice: this.form.feePrice,
					monthlyPrice: this.form.monthlyPrice,
					price: this.form.price,
					terminalSupplier: this.form.terminalSupplier,
					terminalType: this.form.terminalType,
					psp: this.form.psp,
					otherPsp: this.form.otherPsp,
					isShortTermAgreement: this.form.isShortTermAgreement,
					numberOfTerminals: this.form.numberOfTerminals,
				});
			},
		},
	},

	validations: {
		form: {
			customer: {
				required,
			},

			numberOfTerminals: {
				numeric,
			},
		},
	},

	mounted() {
		axios
			.get('/agreement-types')
			.then((response) => {
				this.agreementTypes = response.data;
			})
			.catch((error) => {
				console.error('Error fetching agreement types', error);
			});

		axios
			.get('/steps/lists')
			.then((response) => {
				this.stepLists = response.data;
			})
			.catch((error) => {
				console.error('Error fetching step lists', error);
			});

		axios
			.get(`/original-files`)
			.then((response) => {
				this.originalFiles = response.data;
			})
			.catch((error) => {
				console.error('Unable to fetch original files', error);
			});

		this.load(this.value);
	},

	methods: {
		hasField(field) {
			const map = {
				to: ['Stutttíðarleiga'],
				monthlyPrice: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli'],
				one_time_price: ['Langtíðarleiga', 'Vibrant', 'Stutttíðarleiga', 'Nethandil', 'Tænastusáttmáli'],
				fee_price: ['Stutttíðarleiga'],
				isShortTermAgreement: ['Stutttíðarleiga'],
				swedbank_e_com_number: ['Nethandil'],
				swedbank_pos_number: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				tof_number: ['Langtíðarleiga', 'Vibrant', 'Nethandil', 'Tænastusáttmáli', 'Einans innloysing'],
				terms: ['Stutttíðarleiga'],
				terminalSupplier: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				terminalType: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				psp: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				otherPsp: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				numberOfTerminals: ['Stutttíðarleiga'],
			};

			if (!map[field]) {
				return false;
			}

			return map[field].includes(this.form.selectedType?.name);
		},

		load(data) {
			this.form.customer = data.customer;
			this.form.swedbank_e_com_number = data.swedbank_e_com_number;
			this.form.swedbank_pos_number = data.swedbank_pos_number;
			this.form.tof_number = data.tof_number;
			this.form.from = data.from;
			this.form.to = data.to;
			this.form.monthlyPrice = data.monthlyPrice;
			this.form.oneTimePrice = data.oneTimePrice;
			this.form.terms = data.terms;
			this.form.feePrice = data.feePrice;
			this.form.terminalSupplier = data.terminalSupplier;
			this.form.terminalType = data.terminalType;
			this.form.psp = data.psp;
			this.form.otherPsp = data.otherPsp;
			this.form.isShortTermAgreement = data.isShortTermAgreement;
			this.form.numberOfTerminals = data.numberOfTerminals;

			if (data.type) {
				this.form.selectedType = data.type;
			}

			if (data.stepList?.id) {
				this.form.selectedStepList = { id: data.stepList.id };
			} else {
				this.form.selectedStepList = '';
			}

			if (data.originalFiles) {
				this.form.originalFiles = data.originalFiles;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.selected-customer {
	position: relative;

	display: flex;
	justify-content: space-between;

	padding: 20px;
	line-height: 1;

	background: white;

	border: 1px solid #d8d8d8;
	border-radius: 5px;

	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

	&.disabled {
		background: #f2f2f2;
	}
}

.date-input {
	position: relative;

	.date-input-icon {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}
}
</style>
