<template>
	<main class="container">
		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<div
			v-if="!loading"
			class="grid grid-3-8"
		>
			<div></div>

			<div class="forms">
				<edit-agreement-details v-model="agreement"></edit-agreement-details>

				<div class="flex justify-end mb-40">
					<div
						class="btn hover:background-blue-600"
						@click.prevent="edit"
					>
						Rætta
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';

import EditAgreementDetails from '@/components/forms/EditAgreementDetails.vue';

export default {
	name: 'AgreementEdit',

	components: {
		EditAgreementDetails,
	},

	data() {
		return {
			loading: true,
			agreement: {},
			terminals: [],
		};
	},

	mounted() {
		const id = this.$route.params.id;

		axios
			.get(`/agreements/${id}`)
			.then((response) => {
				this.agreement = response.data;

				this.terminals = response.data.agreementTerminals
					? response.data.agreementTerminals.map((agreementTerminal) => agreementTerminal.terminal)
					: [];

				this.terminals = this.terminals.filter((terminal) => !terminal.deletedAt);
			})
			.catch((error) => {
				console.error('Agreement not found', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		edit() {
			const updateAgreementDto = {
				from: this.agreement.from ? new Date(this.agreement.from) : null,
				to: this.agreement.to ? new Date(this.agreement.to) : null,
				type: this.agreement.type.name,
				tof_number: this.agreement.tof_number,
				swedbank_e_com_number: this.agreement.swedbank_e_com_number,
				swedbank_pos_number: this.agreement.swedbank_pos_number,
				stepList: this.agreement.stepList || null,
				originalFiles: this.agreement.originalFiles,
				terminals: this.terminals,
				terms: this.agreement.terms,
				oneTimePrice: this.agreement.oneTimePrice,
				feePrice: this.agreement.feePrice,
				monthlyPrice: this.agreement.monthlyPrice,
				price: this.agreement.price,
				terminalSupplier: this.agreement.terminalSupplier,
				terminalType: this.agreement.terminalType,
				psp: this.agreement.psp,
				otherPsp: this.agreement.otherPsp,
				isShortTermAgreement: this.agreement.isShortTermAgreement,
				numberOfTerminals: this.agreement.numberOfTerminals,
			};

			axios
				.put(`/agreements/${this.$route.params.id}`, updateAgreementDto)
				.then(() => {
					this.$router.push({ name: 'AgreementShow', params: { id: this.$route.params.id } });
				})
				.catch((error) => {
					console.error('Unable to update agreement', error);
				});
		},

		updateStepList(stepList) {
			this.stepList = stepList;
		},

		updateFrom(from) {
			this.from = from;
		},

		updateTo(to) {
			this.to = to;
		},

		updateType(type) {
			this.type = type;
		},
	},
};
</script>
